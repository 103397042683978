<template>
  <div class="inner-section a11-wrapper">
    <breadcumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">নতুন উদ্ভাবিত চায়ের চারা/উন্নত জাতের বাইক্লোনাল বীজ সরবরাহের জন্য আবেদন</h4>
      </template>
      <template v-slot:body>
        <div class="apps-header">
          <p>স্মারক নং: ২১২৫৬৫</p>
          <p>তারিখ: ২১/১২/২০২২</p>
        </div>
        <div class="apps-subject-wrapper mb-4">
          <p>পরিচালক</p>
          <p>বাংলাদেশ চা গবেষণা ইনস্টিটিউট</p>
          <p>শ্রীমঙ্গল-৩২১০</p>
          <p>জেলা- মৌলভীবাজার</p>
        </div>
        <p class="mb-4">বিষয়: <span class="dynamic-line-wrapper"></span> এর জন্য আবেদন।</p>
        <div class="cover-letter-wrapper">
          <p class="mb-2">প্রিয় মহোদয়,</p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;উপর্যুক্ত বিষয়ে, <span class="dynamic-line-wrapper"></span> চা বাগানে রোপনের জন্য নিম্নলিখিত রোপন সামগ্রী সরবরাহ করার জন্য আপনাকে বিনীত অনুরোধ জানাচ্ছি।</p>
          <div class="table-responsive">
            <table class="table table-bordered text-center mt-4">
              <thead>
                <tr>
                  <th>ক্রমিক নং</th>
                  <th>রোপন সামগ্রী</th>
                  <th>ক্লোন/জাতের নাম</th>
                  <th>সংখ্যা</th>
                  <th>মন্তব্য</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>১</td>
                  <td>নতুন উদ্ভাবিত চায়ের চারা</td>
                  <td></td>
                  <td></td>
                  <td rowspan="2" width="20%"><span
                      style="border-bottom: 1px dashed #444;min-width: 130px;display: inline-block;"></span> টাকার চেক/নগদ প্রদান
                  </td>
                </tr>
                <tr>
                  <td>২</td>
                  <td>বাইক্লোনাল বীজ</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="apps-footer">
          <p>ধন্যবাদান্তে</p>
          <p>আপনার বিশ্বস্ত,</p>
        </div>
      </template>
    </body-card>
  </div>
</template>
<script>
export default {

}
</script>
